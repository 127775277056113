import React, { useEffect, useState } from "react";
import "./UserContent.css";
import loaderImg from "../Images/loader.gif";
import axios from "axios";
import { API } from "../Config/config";
import Select from "react-select";

const mediaTypeFilter = [
  { value: "video", label: "Video" },
  { value: "image", label: "Image" },
  { value: "text", label: "Text" },
];

const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change selected value color to white
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#37305E",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#37305E",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#d3d3d3" : "#37305E",
    color: state.isFocused ? "#000" : "#fff",
  }),
};

export default function UserContent() {
  const [selected, setSelected] = useState("scheduled");
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState([]);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const getContent = () => {
    setLoader(true);
    const mediaType = selectedMediaType ? selectedMediaType.value : null;

    axios
      .get(`${API.apiUrl}/schedulecontent/${selected}`, {
        params: {
          mediaType,
          from: fromDate,
          to: toDate,
        },
      })
      .then((res) => {
        setContent(res.data);
        setLoader(false);
      })
      .catch((e) => {
        console.error(e);
        setLoader(false);
      });
  };

  useEffect(() => {
    getContent();
  }, [selected, selectedMediaType, fromDate, toDate]);

  const resetFilters = () => {
    setSelectedMediaType(null);
    setFromDate("");
    setToDate("");
    getContent(); // Fetch data with default filters
  };

  return (
    <div className="content-library-container">
      {/* Content Library Header */}
      <div className="library-header">
        <h2>My Library</h2>
      </div>

      {/* Toggler */}
      <div className="toggler">
        <div className="inner-toggle">
          <span
            className={`${selected === "scheduled" ? "selected" : ""}`}
            onClick={() => setSelected("scheduled")}
          >
            Scheduled Content
          </span>
          <span
            className={`${selected === "posted" ? "selected" : ""}`}
            onClick={() => setSelected("posted")}
          >
            Posted Content
          </span>
        </div>
      </div>

      {/* Filters */}
      <div className="filter-bar">
        <Select
          defaultValue={selectedMediaType}
          onChange={(option) => setSelectedMediaType(option)}
          options={mediaTypeFilter}
          className="media-filter"
          placeholder={"Media Type"}
          styles={customStyles}
        />
        <div>
          <span>From: </span>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div>
          <span>To: </span>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>

        <button onClick={resetFilters}> Reset Filters </button>
        
      </div>

      {/* Content Display */}
      {loader ? (
        <div className="loader-container">
          <img src={loaderImg} alt="loader" width={200} />
        </div>
      ) : (
        <div className="content-grid">
          {content.length > 0 ? (
            content.map((item) => (
              <div className="content-container" key={item._id}>
                {item.mediaType === "image" ? (
                  <img src={item.mediaUrl} alt="media" />
                ) : item.mediaType === "video" ? (
                  <video src={item.mediaUrl} controls></video>
                ) : (
                  <div className="text-container">{item.content}</div>
                )}
                <div className="text-container">
                  <h3>Content Info:</h3>
                  <hr />
                  <p>
                    <strong>Caption: </strong> {item.content}
                  </p>
                  <p>
                    <strong>Scheduled For: </strong>{" "}
                    {new Date(item.scheduledDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Status: </strong>
                    <span className="status green">
                      {item.status.toUpperCase()}
                    </span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="loader-container">
              <h1>No content found!</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
