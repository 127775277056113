import axios from "axios"
import { API } from "../../Config/config"

export const getContent = (query) => {
    const response = axios.get(`${API.pixabayApiUrl}?q=${query}&key=${API.pixabayApiKey}`).then((res)=>{
        return res;
    }).catch((e)=>{
        return e;
    })

    return response;
}