import React, { useState, useEffect } from "react";
import { allAccounts } from "../../../Services/Facebook/GET/GetAccounts";
import { getGraphData, getPageInfo } from "../../../Services/Facebook/GET/GetPageAnalytics";
import "./Analytics.css";
import {
  CameraIcon,
  FanIcon,
  PictureInPicture2Icon,
  User,
  Video,
} from "lucide-react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export default function Analytics() {
  const [pages, setPages] = useState([]);
  const [pageAccessToken, setPageAccessToken] = useState("");
  const [selectedPage, setSelectedPage] = useState({});
  const [selectedPageId, setSelectedPageId] = useState("");
  const [graphData, setGraphData] = useState([]);

  // Fetch account data and set initial state
  const getData = async () => {
    const response = await allAccounts();

    if (response.status === 200) {
      setPages(response.data.data);
      const firstPage = response.data.data[0];
      setPageAccessToken(firstPage?.access_token || "");
      setSelectedPageId(firstPage?.id || "");
    }
  };

  // Fetch page information whenever `selectedPageId` changes
  const fetchPageInfo = async () => {
    if (!selectedPageId) return; // Guard clause to avoid unnecessary calls

    try {
      const pageResponse = await getPageInfo(selectedPageId, pageAccessToken);
      const graphResponse = await getGraphData(selectedPageId, pageAccessToken);

      setGraphData(graphResponse?.data?.insights?.data || []);
      setSelectedPage(pageResponse.data || {});
    } catch (error) {
      console.error("Error fetching page info or graph data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    fetchPageInfo();
  }, [selectedPageId]);

  // Prepare data for the graph
  const prepareGraphData = () => {
    if (!graphData.length) return { data: {}, options: {} };

    const labels = graphData[0]?.values.map((point) =>
      new Date(point.end_time).toLocaleDateString()
    );

    const viewsData = graphData[0]?.values.map((point) => point.value) || [];
    const impressionsData = graphData[1]?.values.map((point) => point.value) || [];
    const engagementsData = graphData[2]?.values.map((point) => point.value) || [];

    const data = {
      labels,
      datasets: [
        {
          label: "Page Views",
          data: viewsData,
          borderColor: "#FF5733",
          backgroundColor: "rgba(255, 87, 51, 0.2)",
          tension: 0.4,
        },
        {
          label: "Page Impressions",
          data: impressionsData,
          borderColor: "#33A1FF",
          backgroundColor: "rgba(51, 161, 255, 0.2)",
          tension: 0.4,
        },
        {
          label: "Post Engagements",
          data: engagementsData,
          borderColor: "#28A745",
          backgroundColor: "rgba(40, 167, 69, 0.2)",
          tension: 0.4,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    return { data, options };
  };

  const { data, options } = prepareGraphData();

  return (
    <div>
      <div className="topbar">
        {pages.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              setSelectedPageId(item.id);
              setPageAccessToken(item.access_token);
            }}
          >
            <p>{item.name}</p>
          </div>
        ))}
      </div>

      <div className="analytics-container">
        <h1>Analytics for {selectedPage?.name || "No Page Selected"}</h1>
        <p className="analytics-para">
          <User color="#FF4177" /> Total Followers:{" "}
          {selectedPage.followers_count || "N/A"}
        </p>
        <p className="analytics-para">
          <FanIcon color="#FF4177" /> Total Fans: {selectedPage.fan_count || "N/A"}
        </p>

        <div className="icons-wrapper">
          <div className="icons-container">
            <div>
              <CameraIcon />
              <p>Total Photos</p>
              <p>{selectedPage.photos?.data?.length || "N/A"}</p>
            </div>

            <div>
              <Video />
              <p>Total Videos</p>
              <p>{selectedPage.videos?.data?.length || "N/A"}</p>
            </div>

            <div>
              <PictureInPicture2Icon />
              <p>Total Posts</p>
              <p>{selectedPage.published_posts?.data?.length || "N/A"}</p>
            </div>
          </div>
        </div>
        <div className="graph-container">
          <h2>Page Insights: </h2>
          <div className="graph-tags">
            <p><span className="page-views"></span> Page Views</p>
            <p><span className="page-impressions"></span> Page Impressions</p>
            <p><span className="post-engagement"></span> Post Engagement</p>
          </div>
          <div className="graph">
          {graphData.length ? (
            <Line data={data} options={options} />
          ) : (
            <p>Loading graph data...</p>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}
