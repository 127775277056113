import React, { useRef, useState } from 'react'
import { getContent } from '../../Services/Pixabay/ContentRecommentdationService';

export default function ContentRecomendation() {

    const [data, setData] = useState([]);

    const searchRef = useRef();

    const searchContent = async () => {
        const response = await getContent(searchRef.current.value);
        console.log(response);
    }

  return (
    <div>
      <div>
        <input type='text' placeholder='Search..' ref={searchRef}/>
        <button onClick={searchContent}> Search </button>
      </div>

      <div>

      </div>
    </div>
  )
}
